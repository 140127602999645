import React, { useState, useEffect, useCallback } from 'react';
import OpenAI from "openai";
import Webcam from 'react-webcam';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Spinner } from 'react-bootstrap';

const CalorieCalculator = () => {
  const [result, setResult] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [calorie, setCalorie] = useState(null);
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const webcamRef = React.useRef(null);

  // Fetch available media devices
  const handleDevices = useCallback((mediaDevices) => {
    const videoDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
    setDevices(videoDevices);
    if (videoDevices.length > 0) {
      setDeviceId(videoDevices[0].deviceId); // Set default camera
    }
  }, [setDevices]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc.split(',')[1]); // Extract base64 data
    setImagePreview(imageSrc); // Set the preview image
  }, [webcamRef]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1]; // Extract base64 data
      setImage(base64Data);
      setImagePreview(reader.result); // Set the preview image
    };
    reader.readAsDataURL(file);
  };

  const calculateCalories = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });

    setLoading(true);  // Show loading modal

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4o-mini-2024-07-18",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "image_url",
                image_url: {
                  url: `data:image/jpeg;base64,${image}`
                }
              },
              {
                type: "text",
                text: "\nHitung kalori makanan ini"
              }
            ]
          },
          {
            role: "assistant",
            content: [
              {
                type: "text",
                text: "Hitung kalori makanan di gambar ini dalam bahasa. pada bagian jumlah kalori tambahkan format diantara === ==="
              }
            ]
          }
        ],
        temperature: 1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        response_format: {
          type: "text"
        },
      });

      const content = response.choices[0].message.content;
      const matches = content.match(/===([^===]+)===/g);

      const extracted = matches ? matches.map(match => match.replace(/===/g, '')) : [];
      setCalorie(extracted);

      const text = content.replace(/===.*?===/g, '');

      setResult(text);
    } catch (error) {
      console.error("Error calculating calories:", error);
      setResult("Terjadi kesalahan. Silakan coba lagi.");
    } finally {
      setLoading(false);  // Hide loading modal
    }
  };

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <div className="d-flex justify-content-center" style={{ gap: '10px' }}>
          <button className="btn btn-primary" onClick={() => setSelectedOption('camera')}>
            Gunakan Kamera
          </button>
          <button className="btn btn-secondary" onClick={() => setSelectedOption('upload')}>
            Unggah Gambar
          </button>
        </div>
      </div>

      {selectedOption === 'camera' && (
        <div className="card mb-4">
          <div className="card-body text-center">
            <h2>Ambil dari Kamera</h2>

            {/* Dropdown to choose camera if multiple devices are available */}
            {devices.length > 0 && (
              <select
                className="form-control mb-3"
                onChange={(e) => setDeviceId(e.target.value)}
                value={deviceId}
              >
                {devices.map((device, key) => (
                  <option key={key} value={device.deviceId}>
                    {device.label || `Kamera ${key + 1}`}
                  </option>
                ))}
              </select>
            )}

            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ deviceId: deviceId }}
              className="img-fluid mb-3"
            />
            <button className="btn btn-primary" onClick={capture}>Ambil Gambar</button>
          </div>
        </div>
      )}

      {selectedOption === 'upload' && (
        <div className="card mb-4">
          <div className="card-body text-center">
            <h2>Unggah Gambar</h2>
            <input
              type="file"
              accept="image/*"
              className="form-control-file mb-3"
              onChange={handleFileChange}
            />
          </div>
        </div>
      )}

      {imagePreview && (
        <div className="text-center mb-4">
          <h3>Pratinjau Gambar:</h3>
          <img src={imagePreview} alt="Preview" className="img-fluid" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        </div>
      )}

      {image && (
        <div className="text-center">
          <button className="btn btn-success mb-4" onClick={calculateCalories}>
            Hitung Kalori
          </button>
        </div>
      )}

      <Modal show={loading} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
          <p>Menghitung kalori, harap tunggu...</p>
        </Modal.Body>
      </Modal>

      {!loading && result && (
        <div className="card">
          <div className="card-body text-left">
            <h4 className="card-title">Estimasi Kalori = {calorie}</h4>
            <div style={{ textAlign: 'left' }}>
              <ReactMarkdown className="card-text" style={{ textAlign: 'left' }}>{result.replace(/===.*?===/g, '')}</ReactMarkdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalorieCalculator;
