import CalorieCalculator from "./Calorie/CalorieCalculator";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";

const HomePage = () => {
    return (
        <div>
            <Menu />
            <Header />
            <CalorieCalculator />
            <Footer />
        </div>
    );
}

export default HomePage;