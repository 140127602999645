const Header = () => {
    return (
    <div className = "container mt-5" >
        <header className="text-center mb-5">
            <h1 className="display-4">Cek Kalori Pake Foto</h1>
            <img src="image-header.webp" alt="Makanan" className="my-4 mx-auto d-block" style={{ width: '150px', height: '150px' }} />
            <p className="lead">Hitung jumlah kalori dari makanan Anda dengan mudah. Cukup unggah gambar dan biarkan algoritma canggih kami melakukan sisanya. Tetap di jalur dengan tujuan kesehatan Anda secara efisien.</p>
        </header>
    </div >
    );
}
export default Header;